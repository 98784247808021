import axios from "axios";
import { BaseApiDomain } from "../env";

const BaseApi = (ApiEndPoint = null) => {
  // axios instance for making requests
  if (ApiEndPoint == null) {
    const user = JSON.parse(localStorage.getItem("userData"));
    if (user && user.panel == "costcenter") {
      ApiEndPoint = "costcenter";
    } else if (user && user.panel == "staff") {
      ApiEndPoint = "staff";
    }
  }
  const BaseApi = axios.create({
    baseURL: BaseApiDomain + ApiEndPoint,
  });

  BaseApi.interceptors.request.use(
    (config) => {
      // console.log("asdsad");

      const user = JSON.parse(localStorage.getItem("userData"));
      // console.log(user);
      const token = user?.token;
      if (token) {
        config.headers["Authorization"] = "Bearer " + token;
      }
      config.headers["Content-Type"] = "application/json";
      return config;
    },
    (error) => {
      console.log(error);
      // Promise.reject(error);
    }
  );
  return BaseApi;
};

export default BaseApi;
